@tailwind base;

@layer {
  h1 {
    @apply text-2xl;
    @apply text-strong-text;
  }

  h2 {
    @apply text-xl;
    @apply text-strong-text;
  }

  body {
    @apply text-base;
    @apply text-mild-text;
    @apply font-stripe;
    @apply font-light;
  }
}
@tailwind components;
@tailwind utilities;
